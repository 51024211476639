import React from "react";

const SuccessfulPage = () => {

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="bg-green-100 border-l-6 border-green-500 text-green-700 p-4 rounded-lg">
        <p className="text-lg font-semibold">Your payment is received, Thank you</p>
        <p>
          Your order has been successfully confirmed and is now being processed.
        </p>
      </div>
    </div>
  );
};

export default SuccessfulPage;
