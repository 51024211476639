import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Spinner from "../../components/Spinner";
import axios from "axios";

const PaymentPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPayment(token);
  }, [token, navigate]);

  const getPayment = async (token) => {
    try {
      // Simulating an API call
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/orders/${token}/payment-link`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_ALASEEL_API_KEY
          }
        }
      );

      if(data.paymentStatus === "paid") {
        navigate("/order/payment/successful");
      }else if (data.paymentStatus === "pending") {
        setTimeout(() => {
          getPayment(token);
        }, 5000);
      }else{
        if (data.link) {
          window.location.href = data.link;
        }
      }
    } catch (err) {
      console.error("Error occurred:", err);

      navigate("/order/payment/error", {
        state: {
          message: "Link is invalid, Please contact the support team",
        },
      });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner />
    </div>
  );
};

export default PaymentPage;
