import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/not-found/notFound";
import SuccessfulPage from "./pages/payment/SuccessfullPage";
import ErrorPage from "./pages/payment/ErrorPage";
import PaymentPage from "./pages/payment/PaymentPage";

function App() {
  return (
    <Routes>
      <Route path="order/payment/:token" element={<PaymentPage />} />
      <Route path="order/payment/successful" element={<SuccessfulPage />} />
      <Route path="order/payment/error" element={<ErrorPage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
