import React from "react";
import { useLocation } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();
  const message = location.state && location.state.message;

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg">
        <p className="text-lg font-semibold">Order Status: Denied</p>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ErrorPage;
